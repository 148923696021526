<template>
    <div class="contact">
        <div class="t1-c">
            <div
                    class="t1"
                    @mouseover="kfOver"
                    @mouseleave="kfLeave"
                    @click="jumpKf"
            >
                <div class="t1-img kf-img"></div>
                智能客服
                <div class="kf" @mouseover="kfOver" v-if="isShowKf == 1">
                    <div class="kf-body">
                        <div class="kf-title" @click="jumpKf">微信聊天咨询</div>
                        <div class="kf-desc">为您提供专业支持</div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="t1 phone" @mouseover="telOver" @mouseleave="telLeave">
                <div class="t1-img"></div>
                电话咨询
                <div class="phone-c" @mouseover="telOver" v-if="tel == 1">
                    <div class="t4">0755-86607260</div>
                    <div class="t5">周一至周五9:30-19:00</div>
                </div>
            </div>
            <div class="line"></div>
            <div
                    class="t1 wx"
                    @mouseover="wxqrcodeOver"
                    @mouseleave="wxqrcodeLeave"
            >
                <div class="t1-img t1-img2"></div>
                微信咨询
                <div class="wx-img" @mouseover="wxqrcodeOver" v-if="wxqrcode == 1"></div>
            </div>
            <div class="line"></div>
            <div
                    class="t1 group"
                    @mouseover="groupOver"
                    @mouseleave="groupLeave"
            >
                <div class="t1-img t1-img3"></div>
                交流社群
                <div class="group-img" :class="{'group-image-lenovo':$store.state.from=='lenovo','group-image-speedtest':$store.state.from=='speedtest',}" @mouseover="groupOver" v-if="isShowGroup == 1"></div>
            </div>

        </div>
        <div class="back" @click="backClick">
            <div class="back-c">
                <div class="back-img"></div>
                回到顶部
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "GoTop",
        data:function(){
            return {
                isShowKf: 0,
                tel: 0,
                wxqrcode: 0,
                isShowGroup:0
            }
        },
        methods:{
            ...mapActions([
                "sendLog"
            ]),
            testOver:function(){
                console.log(1);
                this.tel = 1;
            },
            groupOver: function () {
                this.isShowGroup = 1;
            },
            groupLeave: function () {
                this.isShowGroup = 0;
            },
            backClick: function () {
                document.getElementById("app").scrollIntoView({ behavior: "smooth" });
            },
            kfOver: function () {
                this.isShowKf = 1;
            },
            kfLeave: function () {
                this.isShowKf = 0;
            },
            jumpKf: function () {
                this.sendLog(["PC_side_clicked","智能客服"]);
                window.open("https://work.weixin.qq.com/kfid/kfcbabd621c41d5a255");
            },
            telOver: function () {
                this.tel = 1;
                this.kfLeave();
            },
            telLeave: function () {
                this.tel = 0;
            },
            wxqrcodeOver: function () {
                this.kfLeave();
                this.wxqrcode = 1;
            },
            wxqrcodeLeave: function () {
                this.wxqrcode = 0;
            },
        }
    }
</script>

<style lang="less">
    .contact {
        position: fixed;
        right: 30px;
        bottom: 25px;
        z-index: 999;
        .t1-c {
            position: relative;
            padding-top: 4px;
            padding-bottom: 4px;
            width: 70px;
            // height: 140px;
            border-radius: 6px;
            box-shadow: 0px 2px 20px 0px rgba(23, 23, 27, 0.1);
            background-color: #ffffff;
            .line {
                width: 40px;
                height: 1px;
                background: #e5ebff;
                margin: 5px auto;
            }
            .t1 {
                position: relative;
                margin: 0 auto;
                width: 60px;
                height: 59px;
                border-radius: 6px;
                font-size: 12px;
                text-align: center;
                color: #57576c;
                // overflow: hidden;
                padding-top: 1px;
                cursor: pointer;
                .t1-img {
                    margin: 0 auto;
                    margin-top: 10px;
                    margin-bottom: 2px;
                    width: 24px;
                    height: 24px;
                    background-image: url("../assets/v4/home57.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
                .t1-img2 {
                    background-image: url("../assets/v4/home59.svg");
                }
                .t1-img3 {
                    background-image: url("../assets/v4/home85.svg");
                }
                .kf-img {
                    background-image: url("../assets/v4/kf-normal.svg");
                }
            }
            .t1:hover {
                color: #3772FF;
                background-color: #f6f8ff;
                .t1-img {
                    background-image: url("../assets/v4/home58.svg");
                }
                .t1-img2 {
                    background-image: url("../assets/v4/home60.svg");
                }
                .t1-img3 {
                    background-image: url("../assets/v4/home86.svg");
                }
                .kf-img {
                    background-image: url("../assets/v4/kf-hover.svg");
                }
            }


            .kf {
                position: absolute;
                left: -175px;
                top: -3px;
                width: 180px;
                height: 70px;
                text-align: left;
                .kf-body {
                    width: 160px;
                    height: 70px;
                    padding-top: 1px;
                    background: #ffffff;
                    box-shadow: 0px 2px 20px 0px rgba(23, 23, 27, 0.1);
                    border-radius: 6px;
                }
                .kf-title {
                    color: #17171b;
                    font-size: 16px;
                    font-weight: 500;
                    // text-decoration: underline;
                    margin-top: 12px;
                    margin-left: 17px;
                    height: 20px;
                    display: inline-block;
                    line-height: 20px;
                    border-bottom: 1px solid #17171b;
                }
                .kf-title:hover {
                    cursor: pointer;
                    color: #3772FF;
                    border-color: #3772FF;
                }
                .kf-desc {
                    margin-top: 6px;
                    margin-left: 17px;
                    color: #57576c;
                    font-size: 12px;
                }
            }
            .phone {
                .phone-c{
                    position: absolute;
                    left: -175px;
                    bottom: -4px;
                    width: 160px;
                    height: 70px;
                    border-radius: 6px;
                    box-shadow: 0px 2px 20px 0px rgba(23, 23, 27, 0.1);
                    background-color: #ffffff;
                    text-align: center;
                    .t4 {
                        margin-top: 14px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #17171b;
                    }
                    .t5 {
                        margin-top: 6px;
                        color: #57576c;
                        font-size: 12px;
                    }
                }
                .phone-c::after{
                    position: absolute;
                    top: 0;
                    right: -30px;
                    content: "";
                    width: 160px;
                    height: 70px;
                    background-color: transparent;
                }

            }
            .wx{
                .wx-img {
                    position: absolute;
                    left: -125px;
                    bottom: -4px;
                    width: 110px;
                    height: 110px;
                    border-radius: 6px;
                    background-color: #ffffff;
                    box-shadow: 0px 2px 20px 0px rgba(23, 23, 27, 0.1);
                    background-image: url("https://cdn.ionewu.com/jdxb_www/images/wechat-contact.png");
                    background-size: 100px 100px;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
                .wx-img::before{
                    position: absolute;
                    right: -20px;
                    content: "";
                    width: 110px;
                    height: 110px;
                    background-color: transparent;
                }
            }
            .group{
                .group-img{
                    position: absolute;
                    left: -125px;
                    bottom: -4px;
                    width: 110px;
                    height: 110px;
                    border-radius: 6px;
                    background-color: #ffffff;
                    box-shadow: 0px 2px 20px 0px rgba(23, 23, 27, 0.1);
                    background-image: url("https://cdn.ionewu.com/jdxb_www/images/wechat-group.jpg");
                    background-size: 100px 100px;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
                .group-image-lenovo {
                    background-image: url("https://cdn.ionewu.com/jdxb_www/images/wechat-group-lenovo.png");
                }
                .group-image-speedtest {
                    background-image: url("https://cdn.ionewu.com/jdxb_www/images/wechat-group-speedtest.png");
                }
                .group-img::before{
                    position: absolute;
                    right: -20px;
                    content: "";
                    width: 110px;
                    height: 110px;
                    background-color: transparent;
                }

            }
        }

        .back {
            margin-top: 14px;
            width: 70px;
            height: 70px;
            border-radius: 6px;
            box-shadow: 0px 2px 10px 0px #e8ecf9;
            background-color: #ffffff;
            text-align: center;
            overflow: hidden;
            color: #17171b;
            cursor: pointer;
            box-shadow: 0px 2px 20px 0px rgba(23, 23, 27, 0.1);


            .back-c {
                margin: 0 auto;
                margin-top: 5px;
                width: 60px;
                height: 60px;
                border-radius: 6px;
                font-size: 12px;
                text-align: center;
                color: #57576c;
                overflow: hidden;
                cursor: pointer;
                .back-img {
                    margin: 0 auto;
                    margin-top: 15px;
                    margin-bottom: 4px;
                    width: 24px;
                    height: 24px;
                    background-image: url("../assets/v4/home61.svg");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
            .back-c:hover {
                color: #3772FF;
                background-color: #f6f8ff;
                .back-img {
                    background-image: url("../assets/v4/home76.svg");
                }
            }
        }
    }
</style>